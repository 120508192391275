import React from "react"
import { connect } from "react-redux"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import "../../components/layout.css"
import {
  Menu,
  Grid,
  Image,
  Header,
  Accordion,
  Icon,
  Table,
  Card,
  Flag,
} from "semantic-ui-react"
import CardCollection from "../../components/products-cards"

const models = [
  {
    id: "7wifi",
    model: "P13  7’’ WiFi",
    os: "Android Oreo (Go edition)",
    chipset: "MT8321 Quad-core 1.3GHz",
    band: "N/A",
    display: "7.0“,TN,1024*600 (Landscape)，Capacitive multi-touch",
    memory: "1GB+16GB (Compatible to 1GB+16GB)",
    dimensions: "189.5x 111 x 9.15mm, 245.2g",
    cameras:
      "front: VGA FF (SW to 2M FF, Compatible with 2M FF | rear: 2MP FF (SW to 5M FF, Compatible with 5M FF)",
    connectivity:
      "WiFi 802.11 b/g/n, BT4.2, FM(use headset for antenna), GPS(default for LATAM)",
    sensor: "G-sensor",
    interface: "Micro USB , 3.5mm jack , TF Card Slot, OTG",
    battery:
      "2580mAh(Min.), Non-removable Up to 7.3 hours (mixed-use battery life)",
    cmf: "Raw Material",
    accessories:
      "default:	Charger 5V/1A, USB cable, QG, LCD Sticker | optional: Headset, Stand flip case, Bumper case, Stylus pen",
    image:
      "https://lh3.googleusercontent.com/pw/ACtC-3cYih9X6ar-s2i7sCW9HSOlCfji2epqgal4DjUhCA_9MuVXkwneMCB_2w51iwcCosh2dWpWY_ObPGRYsY7nQfB6FKj4rSfeDaRYrArRVCrHmtHG9I1pcwSvz8JRYtU7Pp8zr4_2gHfvLjCZqw6CUUE=d",
  },
  {
    id: "73g",
    model: "P13  7’’  3G",
    os: "Android  O（Go edition)",
    chipset: "MT8321 Quad-core 1.3GHz",
    band: "gsm: 850 / 900 / 1800 / 1900MHz | umts: 850/900/1900/2100MHz",
    display: "7.0“,TN,600*1024 (Landscape)",
    memory: "1GB+16GB(Compatible to 1GB+16GB",
    dimensions: "189.5*111*9.15mm, 245g",
    cameras: "front: 0.3MP FF | rear: 2MP FF",
    connectivity: "WiFi 802.11 b/g/n, BT4.2, FM(RDS), GPS",
    sensor: "G-sensor",
    interface: "Micro USB , 3.5mm jack , TF Card Slot, OTG",
    battery: "2580mAh，Non-removable",
    cmf: "Raw material",
    accessories: "default: Charger 5V/1A, USB cable, QG, SIM card slot",
    image:
      "https://lh3.googleusercontent.com/pw/ACtC-3dCjZkGgSPnNsOIt837lxUfpNx0xI9oAgjiT1_tFf3h9zBD0TN7tM3dyZYdmq80_gfFDASn-qgBCe77OIhiDXBLW_PisgjjS0sb7UXa6egUTxVDZn-oR7gMrLHz27js8LxLxQmPE3CMp3C7_hupRCs=d",
  },
  {
    id: "kids",
    model: "P13  7’’ KIDS WiFi",
    os: "Android  O（Go edition)",
    chipset: "MT8321 Quad-core 1.3GHz",
    band: "N/A",
    display:
      "7.0” 1024x600 pixels WSVGA, TFT 16M color display, Capacitive touch screen, Multi-touch 5 points",
    memory: "16GB ROM+ 1G  RAM, External storage up to 128GB",
    dimensions: "189.5x 111 x 9.15mm, 245g",
    cameras: "front: 5MP | rear: 2MP FF",
    video:
      "capture: 720p 30fps | playback: 720p 30fps | streaming: 1080p 30fps | formats: H.263, H.264 AVC, H.265 HEVC, MPEG-4 SP, VP8, VP9, etc",
    audio:
      "speakers: 1 speaker | codes: AAC LC, HE-AACv1 (AAC+)， HE-AACv2 (enhanced AAC+), AAC ELD (enhanced low delay AAC), AMR-NB, AMR-WB, FLAC, MP3, MIDI, Vorbis, PCM/WAVE",
    connectivity:
      "Wi-Fi 802.11 b/g/n, Bluetooth 4.2, Micro USB 2.0 high speed, FM, Wi-Fi Direct, GPS",
    sensor: "G-sensor",
    interface: "Micro USB , 3.5mm jack",
    battery: "2580mAh, 430 hours standby time",
    cmf: "Raw material",
    accessories:
      "default: 5V/0.55A charger, USB cable, QG, Hotline Leaflet, LCD Sticker",
    image:
      "https://lh3.googleusercontent.com/pw/ACtC-3d1UsD2B7b-S4JUUk_IEDaHsWWot5aZ4O0MZxBCc9N9GvMuC6LzMABBDSy1H48ua2aM3NbNcMf1zhwI53CRb92EUX8LJM2MbqojzD6gdPWzq12AozW5g1QFmJXO0_TNG1JabEXg8FoYmXjLY_jUXTk=d",
  },
  {
    id: "10wifi",
    model: "P13 10´´  WiFi",
    os: "Android Oreo (Go edition)",
    chipset: "MT8321 Quad-core 1.3GHz",
    band: "N/A",
    display: "10.1” （1280*800）IPS，Capacitive multi-touch",
    memory: "1 GB+16GB (compatible to 2+16GB)",
    dimension: "255.9*155.4*9.35mm，415g",
    cameras:
      "front: 2MP FF (compatible with 5MP FF) | rear: 2MP FF (compatible with 5MP FF)",
    connectivity: "WiFi 802.11 b/g/n, BT4.2, GPS, FM(headset)",
    sensor: "G-sensor",
    interface: "Micro USB , 3.5mm jack , TF Card Slot, OTG",
    battery: "4000mAh，Non-removable",
    cmf: "Raw Material",
    accesories:
      "default: Charger 5V/1A, USB cable, QG, LCD Sticker | optional: Headset, Stand flip case, Type case",
    image:
      "https://lh3.googleusercontent.com/pw/ACtC-3fweeJLHQO0FYf2FXuIYgSHI7mqGx3bBr_d8Grl_x2BVZIiGKxN6PVRPIBvWznPFfJ-c3YVxwnczA9sfOuaLgtqjLgAnssxT7gFz5bf3LN0Mnk_mA3Tx2Wn9iNtvA4s_I5T_vXmMcbPz54tMa-TDro=d",
  },
]

class Products extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.intiate(models)
  }

  intiate = arr => {
    const obj = {}
    arr.map(x => (obj[x.id] = false))
    return obj
  }

  handleClick = (e, id) => {
    this.setState(prevState => ({
      ...prevState,
      [id]: !prevState[id],
    }))
  }
  render() {
    // console.log("state", this.state)
    const { lang } = this.props

    return (
      <Layout>
        <Header
          size="huge"
          style={{
            textAlign: "center",
            marginTop: "3rem",
            marginBottom: "3rem",
          }}
        >
          {lang === "english" ? "Products" : "Productos"}
        </Header>
        <CardCollection />
        {/* <Grid>
          <Grid.Row>
            <Grid.Column
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
              only="tablet computer"
            >
              <Menu
                style={{
                  position: "fixed",
                  zIndex: "2",
                  backgroundColor: "lightGray",
                }}
              >
                <Menu.Item
                  name="editorials"
                  // active={activeItem === 'editorials'}
                >
                  <a href="/products/#7wifi">P13 7’’ WIFI</a>
                </Menu.Item>

                <Menu.Item
                  name="reviews"
                  // active={activeItem === 'reviews'}
                >
                  <a href="/products/#73g">P13 7’’ 3G</a>
                </Menu.Item>

                <Menu.Item
                  name="upcomingEvents"
                  // active={activeItem === 'upcomingEvents'}
                >
                  <a href="/products/#kids">13 7’’ KIDS WIFI</a>
                </Menu.Item>
                <Menu.Item
                  name="upcomingEvents"
                  // active={activeItem === 'upcomingEvents'}
                >
                  <a href="/products/#10wifi">P13 10´´ WiFi</a>
                </Menu.Item>
              </Menu>
            </Grid.Column>
          </Grid.Row>
        </Grid> */}

        {/* <Grid stackable>
          {models.map((model, i) => {
            return (
              <Grid.Row key={model.id} id={model.id}>
                <Grid.Column
                  style={{
                    width: "70%",
                    margin: "0 auto",
                  }}
                >
                  <hr
                    style={{
                      marginTop: "1rem",
                      backgroundColor: "#74005F",
                      border: "3px solid #74005F",
                      borderRadius: "5px",
                    }}
                  />
                  <Header style={{ marginTop: "1rem" }} size="huge">
                    {model.model}
                  </Header>
                  <Accordion fluid styled>
                    <Accordion.Title
                      active={this.state[model.id]}
                      // active={activeIndex === i++}
                      // index={i}
                      onClick={e => this.handleClick(e, model.id)}
                    >
                      <Icon name="dropdown" />
                      {lang === "english"
                        ? "Specifications"
                        : "Especificaciones"}
                    </Accordion.Title>
                    <Accordion.Content active={this.state[model.id]}>
                      <Table basic="very">
                        <Table.Body>
                          {Object.entries(model).map(([k, v], i) => {
                            return k !== "image" ? (
                              <Table.Row key={k}>
                                <Table.Cell>{k.toUpperCase()}</Table.Cell>
                                <Table.Cell>{v}</Table.Cell>
                              </Table.Row>
                            ) : (
                              <></>
                            )
                          })}
                        </Table.Body>
                      </Table>
                    </Accordion.Content>
                  </Accordion>
                  <Image
                    style={{
                      marginTop: "1rem",
                      height: "400px",
                      width: "auto",
                    }}
                    src={model.image}
                  />
                </Grid.Column>
              </Grid.Row>
            )
          })}
        </Grid> */}

        <SEO title="Products" />
        {/* <Link onClick={() => this.handleClick()} to="/">
          Go back to the homepage
        </Link> */}
      </Layout>
    )
  }
}

export default connect(
  state => ({
    lang: state.lang,
  }),
  null
)(Products)
